import { URL } from './constants';

const headersWithContentType = { 'Content-Type': 'application/json', authorization: localStorage.getItem('auth_token') || undefined };

export class Api {
  constructor(url) {
    this._url = 'https://kittygram-backend.practicum-team.ru';
    // this.setServiceNumber();
  }
  setServiceNumber(number = 101) {
    console.log(`Используем сервис с №${typeof number === undefined ? "без номера" : number}`);
    const append = number && !isNaN(parseInt(number, 10)) ? `-${parseInt(number, 10)}` : '';
    this._url = `https://kittygram-backend${append}.practicum-team.ru`;
  }

  async checkResponse(res) {
    if (res.ok) {
      const data = await res.json();
      return data;
    }
    return res.json().then((res, err) => { console.dir(res); return Promise.reject(err) });
  }

  registerUser(username, password) {
    return fetch(`${this._url}/api/users/`, {
      method: 'POST',
      headers: headersWithContentType,
      body: JSON.stringify({ username, password }),
    }).then(this.checkResponse);
  }

  loginUser(username, password) {
    return fetch(`${this._url}/api/token/login/`, {
      method: 'POST',
      headers: headersWithContentType,
      body: JSON.stringify({ username, password }),
    })
      .then(this.checkResponse)
      .then((data) => {
        if (data.auth_token) {
          localStorage.setItem('auth_token', data.auth_token);
          return data;
        }
        return null;
      });
  }

  logoutUser() {
    return fetch(`${this._url}/api/token/logout/`, {
      method: 'POST',
      headers: headersWithContentType
    }).then((res) => {
      if (res.status === 204) {
        localStorage.removeItem('auth_token');
        return res;
      }
      return null;
    });
  }

  getUser() {
    return fetch(`${this._url}/api/users/me/`, {
      method: 'GET',
      headers: headersWithContentType
    }).then(this.checkResponse);
  }

  getCards(page = 1) {
    return fetch(`${this._url}/api/cats/?page=${page}`, {
      method: 'GET',
      headers: headersWithContentType,
    }).then(this.checkResponse);
  }

  getCard(id) {
    return fetch(`${this._url}/api/cats/${id}/`, {
      method: 'GET',
      headers: headersWithContentType,
    }).then(this.checkResponse);
  }

  getAchievements() {
    return fetch(`${this._url}/api/achievements/`, {
      method: 'GET',
      headers: headersWithContentType,
    }).then(this.checkResponse);
  }

  sendCard(card) {
    return fetch(`${this._url}/api/cats/`, {
      method: 'POST',
      headers: headersWithContentType,
      body: JSON.stringify(card),
    }).then(this.checkResponse);
  }

  updateCard(card, id) {
    return fetch(`${this._url}/api/cats/${id}/`, {
      method: 'PATCH',
      headers: headersWithContentType,
      body: JSON.stringify(card),
    }).then(this.checkResponse);
  }

  deleteCard(id) {
    return fetch(`${this._url}/api/cats/${id}/`, {
      method: 'DELETE',
      headers: headersWithContentType,
    }).then((res) => {
      return { status: res.status === 204 };
    })
  }
}

export default new Api(URL);
